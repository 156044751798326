
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import StockPhotos from '@/components/toolkit/details/row/custom/StockPhotos.vue'
  import { plainToInstance } from 'class-transformer'
  import { Auto } from '@/entities/public'
  import { Stock } from '@/entities/sales'

@Component({
  components: { StockPhotos },
})
  export default class PhotoCell extends Cell {
    auto: Auto = plainToInstance(Auto, {})

    mounted () {
      if (this.item instanceof Stock && this.options?.useAuto) {
        this.auto = this.item.auto
      }
    }
  }
